*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-20 {
  bottom: -5rem;
}

.-bottom-8 {
  bottom: -2rem;
}

.-bottom-96 {
  bottom: -24rem;
}

.-left-16 {
  left: -4rem;
}

.-left-28 {
  left: -7rem;
}

.-left-44 {
  left: -11rem;
}

.-left-5 {
  left: -1.25rem;
}

.-right-16 {
  right: -4rem;
}

.-right-32 {
  right: -8rem;
}

.-right-44 {
  right: -11rem;
}

.-top-10 {
  top: -2.5rem;
}

.-top-14 {
  top: -3.5rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-20 {
  top: -5rem;
}

.-top-32 {
  top: -8rem;
}

.-top-36 {
  top: -9rem;
}

.-top-40 {
  top: -10rem;
}

.-top-44 {
  top: -11rem;
}

.-top-5 {
  top: -1.25rem;
}

.-top-56 {
  top: -14rem;
}

.-top-8 {
  top: -2rem;
}

.-top-80 {
  top: -20rem;
}

.-top-\[158px\] {
  top: -158px;
}

.-top-\[4rem\] {
  top: -4rem;
}

.-top-\[9rem\] {
  top: -9rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-\[-20\%\] {
  bottom: -20%;
}

.bottom-\[25\%\] {
  bottom: 25%;
}

.bottom-\[30\%\] {
  bottom: 30%;
}

.bottom-\[7\%\] {
  bottom: 7%;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-16 {
  left: 4rem;
}

.left-4 {
  left: 1rem;
}

.left-6 {
  left: 1.5rem;
}

.left-8 {
  left: 2rem;
}

.left-\[-25px\] {
  left: -25px;
}

.left-\[7\%\] {
  left: 7%;
}

.right-0 {
  right: 0;
}

.right-1\/2 {
  right: 50%;
}

.right-10 {
  right: 2.5rem;
}

.right-12 {
  right: 3rem;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-28 {
  right: 7rem;
}

.right-4 {
  right: 1rem;
}

.right-44 {
  right: 11rem;
}

.right-5 {
  right: 1.25rem;
}

.right-\[7\%\] {
  right: 7%;
}

.right-\[7rem\] {
  right: 7rem;
}

.start-0 {
  inset-inline-start: 0;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/4 {
  top: 25%;
}

.top-10 {
  top: 2.5rem;
}

.top-14 {
  top: 3.5rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-20 {
  top: 5rem;
}

.top-32 {
  top: 8rem;
}

.top-4 {
  top: 1rem;
}

.top-4\.5 {
  top: 4.5rem;
}

.top-40 {
  top: 10rem;
}

.top-60 {
  top: 15rem;
}

.top-\[-25px\] {
  top: -25px;
}

.top-\[15rem\] {
  top: 15rem;
}

.top-\[20\%\] {
  top: 20%;
}

.top-\[25\%\] {
  top: 25%;
}

.top-\[30\%\] {
  top: 30%;
}

.top-\[43\%\] {
  top: 43%;
}

.top-\[62\%\] {
  top: 62%;
}

.top-\[7\%\] {
  top: 7%;
}

.-z-10 {
  z-index: -10;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.-m-3 {
  margin: -.75rem;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-96 {
  margin-top: -24rem;
}

.-mt-\[18\.5\%\] {
  margin-top: -18.5%;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-\[0rem\] {
  margin-bottom: 0;
}

.mb-\[6rem\] {
  margin-bottom: 6rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[-25px\] {
  margin-top: -25px;
}

.mt-\[3em\] {
  margin-top: 3em;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[1\.5px\] {
  height: 1.5px;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[19rem\] {
  height: 19rem;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[20rem\] {
  height: 20rem;
}

.h-\[21rem\] {
  height: 21rem;
}

.h-\[30em\] {
  height: 30em;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[42em\] {
  height: 42em;
}

.h-\[450px\] {
  height: 450px;
}

.h-\[48rem\] {
  height: 48rem;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50em\] {
  height: 50em;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[530px\] {
  height: 530px;
}

.h-\[5em\] {
  height: 5em;
}

.h-\[6em\] {
  height: 6em;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[800px\] {
  height: 800px;
}

.h-\[920px\] {
  height: 920px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-full {
  min-height: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[130px\] {
  width: 130px;
}

.w-\[1950px\] {
  width: 1950px;
}

.w-\[27em\] {
  width: 27em;
}

.w-\[45\%\] {
  width: 45%;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[75\%\] {
  width: 75%;
}

.w-\[75px\] {
  width: 75px;
}

.w-\[8em\] {
  width: 8em;
}

.w-\[90\%\] {
  width: 90%;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[17rem\] {
  min-width: 17rem;
}

.min-w-full {
  min-width: 100%;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[1536px\] {
  max-width: 1536px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-0 {
  --tw-translate-x: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[30\%\] {
  --tw-translate-x: -30%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-32 {
  --tw-translate-y: -8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[100\%\] {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[200\%\] {
  --tw-translate-y: -200%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2, .translate-x-2\/4 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[18\%\] {
  --tw-translate-x: 18%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[40\%\] {
  --tw-translate-x: 40%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-\[\.0625rem\] {
  gap: .0625rem;
}

.gap-\[1px\] {
  gap: 1px;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.gap-x-6 {
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.gap-x-\[2em\] {
  -moz-column-gap: 2em;
  column-gap: 2em;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-\[3em\] {
  row-gap: 3em;
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-normal {
  white-space: normal;
}

.text-nowrap {
  text-wrap: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-bl-\[50px\] {
  border-bottom-left-radius: 50px;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-br-\[38px\] {
  border-bottom-right-radius: 38px;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tl-\[50px\] {
  border-top-left-radius: 50px;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-tr-\[50px\] {
  border-top-right-radius: 50px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-\[1\.5px\] {
  border-width: 1.5px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-\[\#192b3b\] {
  --tw-border-opacity: 1;
  border-color: rgb(25 43 59 / var(--tw-border-opacity));
}

.border-\[\#232323\] {
  --tw-border-opacity: 1;
  border-color: rgb(35 35 35 / var(--tw-border-opacity));
}

.border-\[\#35333399\] {
  border-color: #35333399;
}

.border-\[\#6B1BD4\] {
  --tw-border-opacity: 1;
  border-color: rgb(107 27 212 / var(--tw-border-opacity));
}

.border-\[\#83CDEB\] {
  --tw-border-opacity: 1;
  border-color: rgb(131 205 235 / var(--tw-border-opacity));
}

.border-\[\#8AE8C8\] {
  --tw-border-opacity: 1;
  border-color: rgb(138 232 200 / var(--tw-border-opacity));
}

.border-\[\#BED3FF\] {
  --tw-border-opacity: 1;
  border-color: rgb(190 211 255 / var(--tw-border-opacity));
}

.border-\[\#CACACA\] {
  --tw-border-opacity: 1;
  border-color: rgb(202 202 202 / var(--tw-border-opacity));
}

.border-\[\#E8CF72\] {
  --tw-border-opacity: 1;
  border-color: rgb(232 207 114 / var(--tw-border-opacity));
}

.border-\[\#EE969F\] {
  --tw-border-opacity: 1;
  border-color: rgb(238 150 159 / var(--tw-border-opacity));
}

.border-\[\#EFBD8B\] {
  --tw-border-opacity: 1;
  border-color: rgb(239 189 139 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.border-violet-900 {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.border-voilet-fade {
  border-color: #8068c4b3;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-white\/20 {
  border-color: #fff3;
}

.border-b-gray-500 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-r-black {
  --tw-border-opacity: 1;
  border-right-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-t-transparent {
  border-top-color: #0000;
}

.bg-\[\#00339D\], .bg-\[\#00339d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 51 157 / var(--tw-bg-opacity));
}

.bg-\[\#0098FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 152 255 / var(--tw-bg-opacity));
}

.bg-\[\#00C9F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 201 247 / var(--tw-bg-opacity));
}

.bg-\[\#021526b8\] {
  background-color: #021526b8;
}

.bg-\[\#02172A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(2 23 42 / var(--tw-bg-opacity));
}

.bg-\[\#0554B1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(5 84 177 / var(--tw-bg-opacity));
}

.bg-\[\#0B111E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(11 17 30 / var(--tw-bg-opacity));
}

.bg-\[\#0ED3BF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 211 191 / var(--tw-bg-opacity));
}

.bg-\[\#1090CE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(16 144 206 / var(--tw-bg-opacity));
}

.bg-\[\#131314\] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 19 20 / var(--tw-bg-opacity));
}

.bg-\[\#162D74\] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 45 116 / var(--tw-bg-opacity));
}

.bg-\[\#192B3B\], .bg-\[\#192b3b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(25 43 59 / var(--tw-bg-opacity));
}

.bg-\[\#1C212A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(28 33 42 / var(--tw-bg-opacity));
}

.bg-\[\#282C35\] {
  --tw-bg-opacity: 1;
  background-color: rgb(40 44 53 / var(--tw-bg-opacity));
}

.bg-\[\#353333\] {
  --tw-bg-opacity: 1;
  background-color: rgb(53 51 51 / var(--tw-bg-opacity));
}

.bg-\[\#3D7670\] {
  --tw-bg-opacity: 1;
  background-color: rgb(61 118 112 / var(--tw-bg-opacity));
}

.bg-\[\#3F647A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(63 100 122 / var(--tw-bg-opacity));
}

.bg-\[\#43D67F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(67 214 127 / var(--tw-bg-opacity));
}

.bg-\[\#4B5970\] {
  --tw-bg-opacity: 1;
  background-color: rgb(75 89 112 / var(--tw-bg-opacity));
}

.bg-\[\#4D5368\] {
  --tw-bg-opacity: 1;
  background-color: rgb(77 83 104 / var(--tw-bg-opacity));
}

.bg-\[\#5C3EB3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(92 62 179 / var(--tw-bg-opacity));
}

.bg-\[\#7C40DF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(124 64 223 / var(--tw-bg-opacity));
}

.bg-\[\#8591B7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(133 145 183 / var(--tw-bg-opacity));
}

.bg-\[\#C2EAFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(194 234 255 / var(--tw-bg-opacity));
}

.bg-\[\#D9D9D9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.bg-\[\#DFDBF5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(223 219 245 / var(--tw-bg-opacity));
}

.bg-\[\#E738A2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 56 162 / var(--tw-bg-opacity));
}

.bg-\[\#EFE9FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 233 255 / var(--tw-bg-opacity));
}

.bg-\[\#F1F1FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 255 / var(--tw-bg-opacity));
}

.bg-\[\#F3F3F3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 243 243 / var(--tw-bg-opacity));
}

.bg-\[\#F4F2FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 242 255 / var(--tw-bg-opacity));
}

.bg-\[\#F4F4FC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 252 / var(--tw-bg-opacity));
}

.bg-\[\#F56402\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 100 2 / var(--tw-bg-opacity));
}

.bg-\[\#F5E5FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 229 255 / var(--tw-bg-opacity));
}

.bg-\[\#F6F9FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 249 255 / var(--tw-bg-opacity));
}

.bg-\[\#F7F7F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

.bg-\[\#F9F9FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 255 / var(--tw-bg-opacity));
}

.bg-\[\#FC6C98\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 108 152 / var(--tw-bg-opacity));
}

.bg-\[\#FD4060\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 64 96 / var(--tw-bg-opacity));
}

.bg-\[\#FEEDE5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 237 229 / var(--tw-bg-opacity));
}

.bg-\[\#FF5353\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 83 83 / var(--tw-bg-opacity));
}

.bg-\[\#FF833B\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 131 59 / var(--tw-bg-opacity));
}

.bg-\[\#FF8F54\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 143 84 / var(--tw-bg-opacity));
}

.bg-\[\#FFC452\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 196 82 / var(--tw-bg-opacity));
}

.bg-\[\#FFD700\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity));
}

.bg-\[\#FFEFE8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 232 / var(--tw-bg-opacity));
}

.bg-\[\#FFF8F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 245 / var(--tw-bg-opacity));
}

.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#f1a500\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 165 0 / var(--tw-bg-opacity));
}

.bg-\[\#f3f0ff9c\] {
  background-color: #f3f0ff9c;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-royal {
  --tw-bg-opacity: 1;
  background-color: rgb(92 62 179 / var(--tw-bg-opacity));
}

.bg-carrot, .bg-cheery-red-dim {
  --tw-bg-opacity: 1;
  background-color: rgb(231 105 103 / var(--tw-bg-opacity));
}

.bg-dark-pink {
  --tw-bg-opacity: 1;
  background-color: rgb(189 53 101 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-peach-medium {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 241 / var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.bg-port-black-coffee {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 51 / var(--tw-bg-opacity));
}

.bg-port-elephant {
  --tw-bg-opacity: 1;
  background-color: rgb(43 63 81 / var(--tw-bg-opacity));
}

.bg-port-green {
  --tw-bg-opacity: 1;
  background-color: rgb(4 186 180 / var(--tw-bg-opacity));
}

.bg-port-white-blueoff {
  --tw-bg-opacity: 1;
  background-color: rgb(243 246 255 / var(--tw-bg-opacity));
}

.bg-port-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(241 165 0 / var(--tw-bg-opacity));
}

.bg-port-yellow-poision {
  --tw-bg-opacity: 1;
  background-color: rgb(239 237 229 / var(--tw-bg-opacity));
}

.bg-primary-color {
  --tw-bg-opacity: 1;
  background-color: rgb(92 62 179 / var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.bg-purple-white {
  --tw-bg-opacity: 1;
  background-color: rgb(244 242 255 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-light {
  background-color: #eeecf499;
}

.bg-slate-semiLight {
  --tw-bg-opacity: 1;
  background-color: rgb(249 248 255 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-typo-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(43 63 81 / var(--tw-bg-opacity));
}

.bg-typo-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(201 88 39 / var(--tw-bg-opacity));
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.bg-voilet-fade {
  background-color: #8068c4b3;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-whiteoff {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-yellow-green-filter {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 227 / var(--tw-bg-opacity));
}

.bg-yellow-white {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-\[url\(\'\.\.\/images\/darkBg\.png\'\)\] {
  background-image: url("darkBg.b2443fd2.png");
}

.bg-about-hero-bg {
  background-image: url("about-hero.827d1de4.webp");
}

.bg-about-mission-bg {
  background-image: url("about-mission.83157c22.webp");
}

.bg-about-us-bg {
  background-image: url("career.4a38b0ec.webp");
}

.bg-contact-convo {
  background-image: url("contact-conversations-bg.0efc7120.png");
}

.bg-education-frame {
  background-image: url("education-frame.e667afd3.png");
}

.bg-edukyte {
  background-image: url("edukyte.df2977bd.png");
}

.bg-environment-frame {
  background-image: url("environment-frame.cda320f0.png");
}

.bg-filterbot {
  background-image: url("filterbot.0b521d93.png");
}

.bg-get-in-touch-mobile {
  background-image: url("get-in-touch-mobile.71e39aae.webp");
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.bg-healthcare-frame {
  background-image: url("healthcare-frame.8bcf7c7e.png");
}

.bg-international {
  background-image: url("internationalBG.c8f7a247.png");
}

.bg-lifestyle-frame {
  background-image: url("lifestyle-frame.d11f090a.png");
}

.bg-main-banner-2 {
  background-image: url("main-banner-2.cc4f001b.webp");
}

.bg-main-banner-ql-white {
  background-image: url("main-banner-white.da3a14a4.png");
}

.bg-media-frame {
  background-image: url("media-frame.05611de8.png");
}

.bg-mnre-bg {
  background-image: url("mnre-bg.2900b01f.webp");
}

.bg-mnre-half-banner {
  background-image: url("mnre-half-banner.e2cabc78.png");
}

.bg-muso-key-bg {
  background-image: url("muzo-backround.7eb033cf.webp");
}

.bg-muso-key-bg-white-blue {
  background-image: url("whit-blue-bg-muso.0b81c6f9.webp");
}

.bg-muso-key-curve-blue {
  background-image: url("bg-curve.a849c2a8.webp");
}

.bg-muso-key-screen {
  background-image: url("muso-key-screen.85c67a7d.webp");
}

.bg-openeo-hero {
  background-image: url("openeo-banner.ff2cf1ea.webp");
}

.bg-openeo-mask {
  background-image: url("openeo-mask.58afbfeb.webp");
}

.bg-piano-transfarent-bg {
  background-image: url("piano-transparent.8e678728.png");
}

.bg-port-biogas {
  background-image: url("biogas-baground.f629f00b.svg");
}

.bg-port-biogas-bg-1 {
  background-image: url("biogas-bg-1.1d9cf61f.svg");
}

.bg-port-biogas-bg-2 {
  background-image: url("biogas-bg-2.da41ebf9.svg");
}

.bg-port-biogas-bg-3 {
  background-image: url("biogas-bg-3.f0728bbd.svg");
}

.bg-port-biogas-bg-4 {
  background-image: url("biogas-bg-4.2bb95155.svg");
}

.bg-port-biogas-bg-5 {
  background-image: url("biogas-bg-5.d9ce95b3.svg");
}

.bg-port-wallup-banner {
  background-image: url("wallup-banner.f2583572.svg");
}

.bg-port-wallup-goal {
  background-image: url("wallup-goal.70fe1fac.svg");
}

.bg-publicsector-frame {
  background-image: url("real-state-new.b21673b8.webp");
}

.bg-quicklee {
  background-image: url("quicklee.688abc82.png");
}

.bg-reactJs-developer-mobile {
  background-image: url("reactJs-developer-mobile.437ef774.webp");
}

.bg-retail-frame {
  background-image: url("retail-frame.92fb63df.png");
}

.bg-solar {
  background-image: url("solar.0a9a1426.png");
}

.bg-stack-development {
  background-image: url("stack-developments-bg.e1c6b6fd.webp");
}

.bg-starfarm-challenges-bg {
  background-image: url("starfarm-challenges.52974694.webp");
}

.bg-starfarm-idia-bg {
  background-image: url("starfarm-idia-banner.d669cad8.webp");
}

.bg-starfarm-solution-bg {
  background-image: url("starfarm-solution.d67bc85f.webp");
}

.bg-swift {
  background-image: url("swiftBanner.124379d4.png");
}

.bg-talent-quest-hero-background-mobile {
  background-image: url("talent-quest-hero-mobile.cc736c34.svg");
}

.bg-travel-frame {
  background-image: url("travel-frame-new.e2fb2ce2.webp");
}

.bg-violet-banner {
  background-image: url("violet-banner.b16924dd.webp");
}

.from-\[\#00339D\] {
  --tw-gradient-from: #00339d var(--tw-gradient-from-position);
  --tw-gradient-to: #00339d00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#2825BF\] {
  --tw-gradient-from: #2825bf var(--tw-gradient-from-position);
  --tw-gradient-to: #2825bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#6734B7\] {
  --tw-gradient-from: #6734b7 var(--tw-gradient-from-position);
  --tw-gradient-to: #6734b700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#7c40df\] {
  --tw-gradient-from: #7c40df var(--tw-gradient-from-position);
  --tw-gradient-to: #7c40df00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#955AC9\] {
  --tw-gradient-from: #955ac9 var(--tw-gradient-from-position);
  --tw-gradient-to: #955ac900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#AD57C6\] {
  --tw-gradient-from: #ad57c6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ad57c600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#FF7C7C\] {
  --tw-gradient-from: #ff7c7c var(--tw-gradient-from-position);
  --tw-gradient-to: #ff7c7c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[rgba\(173\,87\,198\,0\.4\)\] {
  --tw-gradient-from: #ad57c666 var(--tw-gradient-from-position);
  --tw-gradient-to: #ad57c600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-color-and-typography-orange {
  --tw-gradient-from: #ff3f29 var(--tw-gradient-from-position);
  --tw-gradient-to: #ff3f2900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-port-yellow {
  --tw-gradient-from: #f1a500 var(--tw-gradient-from-position);
  --tw-gradient-to: #f1a50000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-800 {
  --tw-gradient-from: #6b21a8 var(--tw-gradient-from-position);
  --tw-gradient-to: #6b21a800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-scope-top-background {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-talent-quest-challanges-left {
  --tw-gradient-from: #ad57c6 var(--tw-gradient-from-position);
  --tw-gradient-to: #ad57c600 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-10\% {
  --tw-gradient-from-position: 10%;
}

.from-80\% {
  --tw-gradient-from-position: 80%;
}

.via-\[\#F4F4FC\] {
  --tw-gradient-to: #f4f4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f4f4fc var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-white {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-80\% {
  --tw-gradient-via-position: 80%;
}

.to-\[\#0166E5\] {
  --tw-gradient-to: #0166e5 var(--tw-gradient-to-position);
}

.to-\[\#17052E\] {
  --tw-gradient-to: #17052e var(--tw-gradient-to-position);
}

.to-\[\#331856\] {
  --tw-gradient-to: #331856 var(--tw-gradient-to-position);
}

.to-\[\#356CD6\] {
  --tw-gradient-to: #356cd6 var(--tw-gradient-to-position);
}

.to-\[\#4069D4\] {
  --tw-gradient-to: #4069d4 var(--tw-gradient-to-position);
}

.to-\[\#C2EAFF\] {
  --tw-gradient-to: #c2eaff var(--tw-gradient-to-position);
}

.to-\[\#F4F4FC\] {
  --tw-gradient-to: #f4f4fc var(--tw-gradient-to-position);
}

.to-\[rgba\(53\,108\,214\,0\.4\)\] {
  --tw-gradient-to: #356cd666 var(--tw-gradient-to-position);
}

.to-color-and-typography-yellow {
  --tw-gradient-to: #ffbf36 var(--tw-gradient-to-position);
}

.to-fuchsia-600 {
  --tw-gradient-to: #c026d3 var(--tw-gradient-to-position);
}

.to-scope-bottom-background {
  --tw-gradient-to: #fff8f5 var(--tw-gradient-to-position);
}

.to-talent-quest-challanges-right {
  --tw-gradient-to: #4b88ff var(--tw-gradient-to-position);
}

.to-typo-orange {
  --tw-gradient-to: #c95827 var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.to-white\/70 {
  --tw-gradient-to: #ffffffb3 var(--tw-gradient-to-position);
}

.to-80\% {
  --tw-gradient-to-position: 80%;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-clip-text {
  background-clip: text;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: 0;
}

.bg-right {
  background-position: 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-top {
  background-position: top;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.stroke-white {
  stroke: #fff;
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.object-fill {
  -o-object-fit: fill;
  object-fit: fill;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-28 {
  padding: 7rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-\[0\.75rem\] {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-\[1em\] {
  padding-top: 1em;
  padding-bottom: 1em;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[28rem\] {
  padding-bottom: 28rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.ps-10 {
  padding-inline-start: 2.5rem;
}

.ps-3 {
  padding-inline-start: .75rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-\[25px\] {
  padding-top: 25px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.align-middle {
  vertical-align: middle;
}

.font-\[Roboto\] {
  font-family: Roboto;
}

.font-\[gilroy-bold\] {
  font-family: gilroy-bold;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3\.5xl {
  font-size: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-7\.5xl {
  font-size: 5.625rem;
}

.text-7xl {
  font-size: 5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[2rem\] {
  font-size: 2rem;
}

.text-\[3rem\] {
  font-size: 3rem;
}

.text-\[5rem\] {
  font-size: 5rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm, .text-tiny {
  font-size: .875rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-xs {
  font-size: .75rem;
}

.text-xs10 {
  font-size: .637rem;
}

.font-\[300\] {
  font-weight: 300;
}

.font-\[400\] {
  font-weight: 400;
}

.font-\[500\] {
  font-weight: 500;
}

.font-\[600\] {
  font-weight: 600;
}

.font-\[700\], .font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-12 {
  line-height: 4rem;
}

.leading-13 {
  line-height: 3rem;
}

.leading-14 {
  line-height: 4.5rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[28\.8px\] {
  line-height: 28.8px;
}

.leading-\[42px\] {
  line-height: 42px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-\[\#02172A\] {
  --tw-text-opacity: 1;
  color: rgb(2 23 42 / var(--tw-text-opacity));
}

.text-\[\#0354CF\] {
  --tw-text-opacity: 1;
  color: rgb(3 84 207 / var(--tw-text-opacity));
}

.text-\[\#0CC998\] {
  --tw-text-opacity: 1;
  color: rgb(12 201 152 / var(--tw-text-opacity));
}

.text-\[\#202026\] {
  --tw-text-opacity: 1;
  color: rgb(32 32 38 / var(--tw-text-opacity));
}

.text-\[\#232323\] {
  --tw-text-opacity: 1;
  color: rgb(35 35 35 / var(--tw-text-opacity));
}

.text-\[\#2B0056\] {
  --tw-text-opacity: 1;
  color: rgb(43 0 86 / var(--tw-text-opacity));
}

.text-\[\#3E3E3E\] {
  --tw-text-opacity: 1;
  color: rgb(62 62 62 / var(--tw-text-opacity));
}

.text-\[\#4D4D4D\] {
  --tw-text-opacity: 1;
  color: rgb(77 77 77 / var(--tw-text-opacity));
}

.text-\[\#5C3EB3\] {
  --tw-text-opacity: 1;
  color: rgb(92 62 179 / var(--tw-text-opacity));
}

.text-\[\#6A49BC\] {
  --tw-text-opacity: 1;
  color: rgb(106 73 188 / var(--tw-text-opacity));
}

.text-\[\#6B1BD4\] {
  --tw-text-opacity: 1;
  color: rgb(107 27 212 / var(--tw-text-opacity));
}

.text-\[\#802FDE\] {
  --tw-text-opacity: 1;
  color: rgb(128 47 222 / var(--tw-text-opacity));
}

.text-\[\#AAC77B\] {
  --tw-text-opacity: 1;
  color: rgb(170 199 123 / var(--tw-text-opacity));
}

.text-\[\#CDF094\] {
  --tw-text-opacity: 1;
  color: rgb(205 240 148 / var(--tw-text-opacity));
}

.text-\[\#DBB9FD\] {
  --tw-text-opacity: 1;
  color: rgb(219 185 253 / var(--tw-text-opacity));
}

.text-\[\#E7E7E7\] {
  --tw-text-opacity: 1;
  color: rgb(231 231 231 / var(--tw-text-opacity));
}

.text-\[\#F56402\] {
  --tw-text-opacity: 1;
  color: rgb(245 100 2 / var(--tw-text-opacity));
}

.text-\[\#FEEDE5\] {
  --tw-text-opacity: 1;
  color: rgb(254 237 229 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black-light {
  --tw-text-opacity: 1;
  color: rgb(5 5 5 / var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.text-blue-royal {
  --tw-text-opacity: 1;
  color: rgb(92 62 179 / var(--tw-text-opacity));
}

.text-carrot {
  --tw-text-opacity: 1;
  color: rgb(231 105 103 / var(--tw-text-opacity));
}

.text-cheery-red {
  --tw-text-opacity: 1;
  color: rgb(231 98 95 / var(--tw-text-opacity));
}

.text-cheery-red-dim {
  --tw-text-opacity: 1;
  color: rgb(231 105 103 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-heading-black {
  --tw-text-opacity: 1;
  color: rgb(17 4 55 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.text-para-text-color {
  --tw-text-opacity: 1;
  color: rgb(95 95 104 / var(--tw-text-opacity));
}

.text-primary-color {
  --tw-text-opacity: 1;
  color: rgb(92 62 179 / var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-redish-300 {
  --tw-text-opacity: 1;
  color: rgb(206 105 139 / var(--tw-text-opacity));
}

.text-royal-blue-filter {
  color: #5c3eb3b3;
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-sub-heading-black {
  --tw-text-opacity: 1;
  color: rgb(5 5 5 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/20 {
  color: #fff3;
}

.text-white\/5 {
  color: #ffffff0d;
}

.text-whiteoff {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-yellow-green {
  --tw-text-opacity: 1;
  color: rgb(170 199 123 / var(--tw-text-opacity));
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-opacity-10 {
  --tw-text-opacity: .1;
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-1 {
  text-underline-offset: 1px;
}

.underline-offset-8 {
  text-underline-offset: 8px;
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_10px_1px_gray\] {
  --tw-shadow: inset 0 0 10px 1px gray;
  --tw-shadow-colored: inset 0 0 10px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-gray-100 {
  --tw-shadow-color: #f3f4f6;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline {
  outline-style: solid;
}

.outline-4 {
  outline-width: 4px;
}

.outline-\[\#fbc6cb\] {
  outline-color: #fbc6cb;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert-0 {
  --tw-invert: invert(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-100 {
  transition-delay: .1s;
}

.delay-1000 {
  transition-delay: 1s;
}

.delay-75 {
  transition-delay: 75ms;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: 1s fadeInUp;
}

@keyframes Scale {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.Scale {
  animation: 1s Scale;
}

@keyframes fadeInUpL {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInUpL {
  animation: 1s fadeInUpL;
}

@keyframes fadeInUpR {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInUpR {
  animation: 1s fadeInUpR;
}

@media (width >= 768px) {
  .md\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .md\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .md\:container {
      max-width: 1536px;
    }
  }
}

@media (width >= 1280px) {
  .xl\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .xl\:container {
      max-width: 1536px;
    }
  }
}

@media (width >= 1536px) {
  .\32 xl\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .\32 xl\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .\32 xl\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .\32 xl\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .\32 xl\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .\32 xl\:container {
      max-width: 1536px;
    }
  }
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-\[\#eddfff\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 223 255 / var(--tw-bg-opacity));
}

.hover\:bg-cheery-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(231 98 95 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.hover\:bg-purple-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 242 255 / var(--tw-bg-opacity));
}

.hover\:bg-violet-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:bg-dribbble-red:hover {
  background-image: url("dribbble-red.8ea82579.png");
}

.hover\:bg-facebook-red:hover {
  background-image: url("facebook-red.e545c35b.png");
}

.hover\:bg-insta-red:hover {
  background-image: url("insta-red.bf5c4d84.png");
}

.hover\:bg-linkdin-red:hover {
  background-image: url("linkdin-red.a2ebebad.png");
}

.hover\:text-\[\#5C3EB3\]:hover {
  --tw-text-opacity: 1;
  color: rgb(92 62 179 / var(--tw-text-opacity));
}

.hover\:text-\[\#DBB9FD\]:hover {
  --tw-text-opacity: 1;
  color: rgb(219 185 253 / var(--tw-text-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-carrot:hover {
  --tw-text-opacity: 1;
  color: rgb(231 105 103 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-redish-400:hover {
  --tw-text-opacity: 1;
  color: rgb(255 124 124 / var(--tw-text-opacity));
}

.hover\:text-violet-700:hover {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.hover\:text-violet-900:hover {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:underline-offset-8:hover {
  text-underline-offset: 8px;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:ease-in:hover {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.focus\:border-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.focus\:border-primary-color:focus {
  --tw-border-opacity: 1;
  border-color: rgb(92 62 179 / var(--tw-border-opacity));
}

.focus\:border-violet-900:focus {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.active\:text-violet-700:active {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.group:hover .group-hover\:ml-4 {
  margin-left: 1rem;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:translate-x-2 {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-x-3 {
  --tw-translate-x: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:translate-x-4 {
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-\[\#5C3EB3\], .group:hover .group-hover\:text-blue-royal {
  --tw-text-opacity: 1;
  color: rgb(92 62 179 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-yellow-green {
  --tw-text-opacity: 1;
  color: rgb(170 199 123 / var(--tw-text-opacity));
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

.group:hover .group-hover\:underline-offset-8 {
  text-underline-offset: 8px;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.group:hover .group-hover\:transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (width >= 640px) {
  .sm\:-top-12 {
    top: -3rem;
  }

  .sm\:bottom-\[-40\%\] {
    bottom: -40%;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:-mt-\[17\.5\%\] {
    margin-top: -17.5%;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-48 {
    height: 12rem;
  }

  .sm\:h-52 {
    height: 13rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-32 {
    width: 8rem;
  }

  .sm\:w-36 {
    width: 9rem;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:w-9 {
    width: 2.25rem;
  }

  .sm\:w-\[27\%\] {
    width: 27%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-\[600px\] {
    max-width: 600px;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-around {
    justify-content: space-around;
  }

  .sm\:gap-x-12 {
    -moz-column-gap: 3rem;
    column-gap: 3rem;
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pb-3 {
    padding-bottom: .75rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-start {
    text-align: start;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
  }

  .sm\:text-3\.5xl {
    font-size: 2rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
  }
}

@media (width >= 768px) {
  .md\:invisible {
    visibility: hidden;
  }

  .md\:static {
    position: static;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:-top-16 {
    top: -4rem;
  }

  .md\:left-\[7\%\] {
    left: 7%;
  }

  .md\:right-auto {
    right: auto;
  }

  .md\:order-first {
    order: -9999;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:float-none {
    float: none;
  }

  .md\:m-auto {
    margin: auto;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mb-3 {
    margin-bottom: .75rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-44 {
    margin-bottom: 11rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mt-28 {
    margin-top: 7rem;
  }

  .md\:mt-3 {
    margin-top: .75rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-7 {
    margin-top: 1.75rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-2 {
    height: .5rem;
  }

  .md\:h-5\/6 {
    height: 83.3333%;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:h-72 {
    height: 18rem;
  }

  .md\:h-\[20rem\] {
    height: 20rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-\[19rem\] {
    width: 19rem;
  }

  .md\:w-\[30\%\] {
    width: 30%;
  }

  .md\:w-\[35\%\] {
    width: 35%;
  }

  .md\:w-\[38\%\] {
    width: 38%;
  }

  .md\:w-\[40\%\] {
    width: 40%;
  }

  .md\:w-\[45\%\] {
    width: 45%;
  }

  .md\:w-\[48\%\] {
    width: 48%;
  }

  .md\:w-\[55\%\] {
    width: 55%;
  }

  .md\:w-\[58\%\] {
    width: 58%;
  }

  .md\:w-\[60\%\] {
    width: 60%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:min-w-fit {
    min-width: fit-content;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:-translate-y-12 {
    --tw-translate-y: -3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:gap-3 {
    gap: .75rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-x-16 {
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }

  .md\:gap-x-6 {
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .md\:gap-y-12 {
    row-gap: 3rem;
  }

  .md\:gap-y-20 {
    row-gap: 5rem;
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-r-0 {
    border-right-width: 0;
  }

  .md\:border-r-2 {
    border-right-width: 2px;
  }

  .md\:bg-\[\#ef6351\] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 99 81 / var(--tw-bg-opacity));
  }

  .md\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity));
  }

  .md\:bg-\[url\(\'https\:\/\/ql-web-images\.s3\.us-east-2\.amazonaws\.com\/ql-images\/machine-learning-hero\.png\'\)\] {
    background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/machine-learning-hero.png");
  }

  .md\:bg-blue-banner {
    background-image: url("bg-blue-banners.c4675d26.png");
  }

  .md\:bg-contact-convo-half {
    background-image: url("contact-convo-halfs.dc7eb66c.png");
  }

  .md\:bg-error-page-bg {
    background-image: url("error-page.32688c08.png");
  }

  .md\:bg-plately-bg {
    background-image: url("hero-section-plately.346e63b9.png");
  }

  .md\:bg-center {
    background-position: center;
  }

  .md\:bg-top {
    background-position: top;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-2 {
    padding: .5rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:px-\[29px\] {
    padding-left: 29px;
    padding-right: 29px;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:py-\[13px\] {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .md\:py-\[15px\] {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pl-16 {
    padding-left: 4rem;
  }

  .md\:pl-20 {
    padding-left: 5rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-11 {
    padding-right: 2.75rem;
  }

  .md\:pr-16 {
    padding-right: 4rem;
  }

  .md\:pr-20 {
    padding-right: 5rem;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pt-14 {
    padding-top: 3.5rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
  }

  .md\:text-6xl {
    font-size: 4rem;
  }

  .md\:text-\[\.9rem\] {
    font-size: .9rem;
  }

  .md\:text-\[8rem\] {
    font-size: 8rem;
  }

  .md\:text-base {
    font-size: 1rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
  }

  .md\:text-sm {
    font-size: .875rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
  }

  .md\:leading-4 {
    line-height: 1rem;
  }

  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .md\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .md\:hover\:bg-blue-royal:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(92 62 179 / var(--tw-bg-opacity));
  }

  .md\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .group:hover .md\:group-hover\:invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  @media (width >= 1024px) {
    .md\:lg\:w-4\/5 {
      width: 80%;
    }
  }
}

@media (width >= 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:-left-32 {
    left: -8rem;
  }

  .lg\:-top-10 {
    top: -2.5rem;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:bottom-\[-28\%\] {
    bottom: -28%;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-first {
    order: -9999;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:m-2 {
    margin: .5rem;
  }

  .lg\:m-2\.5 {
    margin: .625rem;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .lg\:my-18 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .lg\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-28 {
    margin-bottom: 7rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:mb-\[25rem\] {
    margin-bottom: 25rem;
  }

  .lg\:ml-11 {
    margin-left: 2.75rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-3 {
    margin-right: .75rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:h-5\/6 {
    height: 83.3333%;
  }

  .lg\:h-52 {
    height: 13rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:h-64 {
    height: 16rem;
  }

  .lg\:h-9 {
    height: 2.25rem;
  }

  .lg\:h-\[19rem\] {
    height: 19rem;
  }

  .lg\:h-\[20rem\] {
    height: 20rem;
  }

  .lg\:h-\[36rem\] {
    height: 36rem;
  }

  .lg\:h-\[50px\] {
    height: 50px;
  }

  .lg\:h-\[530px\] {
    height: 530px;
  }

  .lg\:h-\[600px\] {
    height: 600px;
  }

  .lg\:h-\[auto\] {
    height: auto;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:w-0 {
    width: 0;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-11 {
    width: 2.75rem;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-14 {
    width: 3.5rem;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-4\/6 {
    width: 66.6667%;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-9 {
    width: 2.25rem;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-\[12rem\] {
    width: 12rem;
  }

  .lg\:w-\[16rem\] {
    width: 16rem;
  }

  .lg\:w-\[35\%\] {
    width: 35%;
  }

  .lg\:w-\[38rem\] {
    width: 38rem;
  }

  .lg\:w-\[40\%\] {
    width: 40%;
  }

  .lg\:w-\[45\%\] {
    width: 45%;
  }

  .lg\:w-\[47\%\] {
    width: 47%;
  }

  .lg\:w-\[50\%\] {
    width: 50%;
  }

  .lg\:w-\[90\%\] {
    width: 90%;
  }

  .lg\:w-\[95\%\] {
    width: 95%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-5xl {
    max-width: 64rem;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-\[100\%\] {
    max-width: 100%;
  }

  .lg\:max-w-\[80\%\] {
    max-width: 80%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:-translate-x-0 {
    --tw-translate-x: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:transform-none {
    transform: none;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-28 {
    gap: 7rem;
  }

  .lg\:gap-3 {
    gap: .75rem;
  }

  .lg\:gap-32 {
    gap: 8rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:gap-x-16 {
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }

  .lg\:gap-x-20 {
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }

  .lg\:gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }

  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-r-0 {
    border-right-width: 0;
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:bg-\[\#FEEDE5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 237 229 / var(--tw-bg-opacity));
  }

  .lg\:bg-\[\#ef6351\] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 99 81 / var(--tw-bg-opacity));
  }

  .lg\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity));
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .lg\:bg-\[url\(\'https\:\/\/ql-web-images\.s3\.us-east-2\.amazonaws\.com\/ql-images\/android-hero-section\.webp\'\)\] {
    background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/android-hero-section.webp");
  }

  .lg\:bg-\[url\(\'https\:\/\/ql-web-images\.s3\.us-east-2\.amazonaws\.com\/ql-images\/flutter-section-banner\.png\'\)\] {
    background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/flutter-section-banner.png");
  }

  .lg\:bg-\[url\(\'https\:\/\/ql-web-images\.s3\.us-east-2\.amazonaws\.com\/ql-images\/ios-section-banner\.png\'\)\] {
    background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/ios-section-banner.png");
  }

  .lg\:bg-\[url\(\'https\:\/\/ql-web-images\.s3\.us-east-2\.amazonaws\.com\/ql-images\/native-section-banner\.png\'\)\] {
    background-image: url("https://ql-web-images.s3.us-east-2.amazonaws.com/ql-images/native-section-banner.png");
  }

  .lg\:bg-adapt-solutions-wave-1 {
    background-image: url("adapt-solutions-wave-1.f24e77b1.webp");
  }

  .lg\:bg-adapt-solutions-wave-2 {
    background-image: url("adapt-solutions-wave-2.8b9e64b5.webp");
  }

  .lg\:bg-talent-quest-hero-background {
    background-image: url("talent-quest-hero-bg.4c890917.svg");
  }

  .lg\:from-white {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .lg\:to-\[\#F4F4FC\] {
    --tw-gradient-to: #f4f4fc var(--tw-gradient-to-position);
  }

  .lg\:bg-contain {
    background-size: contain;
  }

  .lg\:bg-cover {
    background-size: cover;
  }

  .lg\:bg-center {
    background-position: center;
  }

  .lg\:bg-right {
    background-position: 100%;
  }

  .lg\:bg-right-top {
    background-position: 100% 0;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-1 {
    padding: .25rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-14 {
    padding: 3.5rem;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:p-24 {
    padding: 6rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-40 {
    padding: 10rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pb-3 {
    padding-bottom: .75rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pl-14 {
    padding-left: 3.5rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pr-1 {
    padding-right: .25rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pr-14 {
    padding-right: 3.5rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pr-24 {
    padding-right: 6rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pt-3 {
    padding-top: .75rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pt-96 {
    padding-top: 24rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-start {
    text-align: start;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
  }

  .lg\:text-\[22px\] {
    font-size: 22px;
  }

  .lg\:text-\[35px\] {
    font-size: 35px;
  }

  .lg\:text-\[6rem\] {
    font-size: 6rem;
  }

  .lg\:text-base {
    font-size: 1rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-11 {
    line-height: 3.5rem;
  }

  .lg\:leading-12 {
    line-height: 4rem;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:leading-7 {
    line-height: 1.75rem;
  }

  .lg\:leading-8 {
    line-height: 2rem;
  }

  .lg\:leading-\[1\.75rem\] {
    line-height: 1.75rem;
  }

  .lg\:leading-\[49px\] {
    line-height: 49px;
  }

  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .group:hover .lg\:group-hover\:block {
    display: block;
  }

  .group:hover .lg\:group-hover\:hidden {
    display: none;
  }

  @media (width >= 1280px) {
    .lg\:xl\:pt-14 {
      padding-top: 3.5rem;
    }
  }
}

@media (width >= 1280px) {
  .xl\:visible {
    visibility: visible;
  }

  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:sticky {
    position: sticky;
  }

  .xl\:-left-32 {
    left: -8rem;
  }

  .xl\:-left-\[2rem\] {
    left: -2rem;
  }

  .xl\:-right-\[11rem\] {
    right: -11rem;
  }

  .xl\:-right-\[2\.5rem\] {
    right: -2.5rem;
  }

  .xl\:-top-10 {
    top: -2.5rem;
  }

  .xl\:left-\[25rem\] {
    left: 25rem;
  }

  .xl\:right-1 {
    right: .25rem;
  }

  .xl\:right-24 {
    right: 6rem;
  }

  .xl\:top-48, .xl\:top-\[12rem\] {
    top: 12rem;
  }

  .xl\:top-\[9rem\] {
    top: 9rem;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-first {
    order: -9999;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-start-4 {
    grid-column-start: 4;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:float-right {
    float: right;
  }

  .xl\:float-left {
    float: left;
  }

  .xl\:m-0 {
    margin: 0;
  }

  .xl\:m-auto {
    margin: auto;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:-mt-60 {
    margin-top: -15rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:mb-28 {
    margin-bottom: 7rem;
  }

  .xl\:mb-3 {
    margin-bottom: .75rem;
  }

  .xl\:mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:mb-36 {
    margin-bottom: 9rem;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:mb-40 {
    margin-bottom: 10rem;
  }

  .xl\:mb-52 {
    margin-bottom: 13rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:mb-\[25rem\] {
    margin-bottom: 25rem;
  }

  .xl\:ml-auto {
    margin-left: auto;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:mr-12 {
    margin-right: 3rem;
  }

  .xl\:mr-20 {
    margin-right: 5rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:mt-14 {
    margin-top: 3.5rem;
  }

  .xl\:mt-16 {
    margin-top: 4rem;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mt-24 {
    margin-top: 6rem;
  }

  .xl\:mt-28 {
    margin-top: 7rem;
  }

  .xl\:mt-36 {
    margin-top: 9rem;
  }

  .xl\:mt-40 {
    margin-top: 10rem;
  }

  .xl\:mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:mt-56 {
    margin-top: 14rem;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:mt-8 {
    margin-top: 2rem;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline-block {
    display: inline-block;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:inline-flex {
    display: inline-flex;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:h-1\/2 {
    height: 50%;
  }

  .xl\:h-16 {
    height: 4rem;
  }

  .xl\:h-20 {
    height: 5rem;
  }

  .xl\:h-32 {
    height: 8rem;
  }

  .xl\:h-72 {
    height: 18rem;
  }

  .xl\:h-80 {
    height: 20rem;
  }

  .xl\:h-96 {
    height: 24rem;
  }

  .xl\:h-\[19rem\] {
    height: 19rem;
  }

  .xl\:h-\[20rem\] {
    height: 20rem;
  }

  .xl\:h-\[265px\] {
    height: 265px;
  }

  .xl\:h-\[35rem\] {
    height: 35rem;
  }

  .xl\:h-\[36rem\] {
    height: 36rem;
  }

  .xl\:h-\[50px\] {
    height: 50px;
  }

  .xl\:h-\[530px\] {
    height: 530px;
  }

  .xl\:h-\[600px\] {
    height: 600px;
  }

  .xl\:h-\[771px\] {
    height: 771px;
  }

  .xl\:h-\[900px\] {
    height: 900px;
  }

  .xl\:h-\[auto\], .xl\:h-auto {
    height: auto;
  }

  .xl\:h-full {
    height: 100%;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-10\/12 {
    width: 83.3333%;
  }

  .xl\:w-11\/12 {
    width: 91.6667%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-2\/4 {
    width: 50%;
  }

  .xl\:w-2\/5 {
    width: 40%;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:w-3\/5 {
    width: 60%;
  }

  .xl\:w-4\/5 {
    width: 80%;
  }

  .xl\:w-4\/6 {
    width: 66.6667%;
  }

  .xl\:w-40 {
    width: 10rem;
  }

  .xl\:w-44 {
    width: 11rem;
  }

  .xl\:w-5\/6 {
    width: 83.3333%;
  }

  .xl\:w-56 {
    width: 14rem;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-7\/12 {
    width: 58.3333%;
  }

  .xl\:w-8\/12 {
    width: 66.6667%;
  }

  .xl\:w-80 {
    width: 20rem;
  }

  .xl\:w-9\/12 {
    width: 75%;
  }

  .xl\:w-96 {
    width: 24rem;
  }

  .xl\:w-\[265px\] {
    width: 265px;
  }

  .xl\:w-\[33\%\] {
    width: 33%;
  }

  .xl\:w-\[38rem\] {
    width: 38rem;
  }

  .xl\:w-\[65\%\] {
    width: 65%;
  }

  .xl\:w-\[70\%\] {
    width: 70%;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:max-w-5xl {
    max-width: 64rem;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:max-w-\[1240px\] {
    max-width: 1240px;
  }

  .xl\:max-w-\[159\.563rem\] {
    max-width: 159.563rem;
  }

  .xl\:max-w-full {
    max-width: 100%;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:translate-y-28 {
    --tw-translate-y: 7rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:items-end {
    align-items: flex-end;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:justify-around {
    justify-content: space-around;
  }

  .xl\:gap-0 {
    gap: 0;
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:gap-14 {
    gap: 3.5rem;
  }

  .xl\:gap-2 {
    gap: .5rem;
  }

  .xl\:gap-24 {
    gap: 6rem;
  }

  .xl\:gap-28 {
    gap: 7rem;
  }

  .xl\:gap-32 {
    gap: 8rem;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:gap-6 {
    gap: 1.5rem;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:gap-x-16 {
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }

  .xl\:gap-x-20 {
    -moz-column-gap: 5rem;
    column-gap: 5rem;
  }

  .xl\:gap-x-24 {
    -moz-column-gap: 6rem;
    column-gap: 6rem;
  }

  .xl\:gap-x-28 {
    -moz-column-gap: 7rem;
    column-gap: 7rem;
  }

  .xl\:gap-y-20 {
    row-gap: 5rem;
  }

  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:self-end {
    align-self: flex-end;
  }

  .xl\:overflow-hidden {
    overflow: hidden;
  }

  .xl\:rounded-\[14\.5rem\] {
    border-radius: 14.5rem;
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:border-0 {
    border-width: 0;
  }

  .xl\:bg-\[\#ef6351\] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 99 81 / var(--tw-bg-opacity));
  }

  .xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity));
  }

  .xl\:bg-transparent {
    background-color: #0000;
  }

  .xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .xl\:bg-get-in-touch {
    background-image: url("get-in-touch.e113c219.webp");
  }

  .xl\:bg-muso-keys-white-bg {
    background-image: url("muso-keys-white-bg.b530ed38.png");
  }

  .xl\:bg-plately-white-bg {
    background-image: url("web-design-white-background.df2def6a.webp");
  }

  .xl\:bg-reactJs-developer {
    background-image: url("reactJs-developerBG.6238e4d6.webp");
  }

  .xl\:bg-talent-quest-hero-background {
    background-image: url("talent-quest-hero-bg.4c890917.svg");
  }

  .xl\:bg-cover {
    background-size: cover;
  }

  .xl\:bg-bottom {
    background-position: bottom;
  }

  .xl\:bg-center {
    background-position: center;
  }

  .xl\:bg-right {
    background-position: 100%;
  }

  .xl\:bg-right-top {
    background-position: 100% 0;
  }

  .xl\:p-0 {
    padding: 0;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-12 {
    padding: 3rem;
  }

  .xl\:p-16 {
    padding: 4rem;
  }

  .xl\:p-2 {
    padding: .5rem;
  }

  .xl\:p-20 {
    padding: 5rem;
  }

  .xl\:p-3 {
    padding: .75rem;
  }

  .xl\:p-32 {
    padding: 8rem;
  }

  .xl\:p-40 {
    padding: 10rem;
  }

  .xl\:p-5 {
    padding: 1.25rem;
  }

  .xl\:p-6 {
    padding: 1.5rem;
  }

  .xl\:p-60 {
    padding: 15rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }

  .xl\:p-\[6px\] {
    padding: 6px;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  @media (width >= 1280px) {
    .xl\:xl\:px-24 {
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:pb-28 {
    padding-bottom: 7rem;
  }

  .xl\:pb-3 {
    padding-bottom: .75rem;
  }

  .xl\:pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:pb-\[28rem\] {
    padding-bottom: 28rem;
  }

  .xl\:pl-0 {
    padding-left: 0;
  }

  .xl\:pl-14 {
    padding-left: 3.5rem;
  }

  .xl\:pl-16 {
    padding-left: 4rem;
  }

  .xl\:pl-20 {
    padding-left: 5rem;
  }

  .xl\:pl-24 {
    padding-left: 6rem;
  }

  .xl\:pl-28 {
    padding-left: 7rem;
  }

  .xl\:pl-32 {
    padding-left: 8rem;
  }

  .xl\:pl-4 {
    padding-left: 1rem;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pr-16 {
    padding-right: 4rem;
  }

  .xl\:pr-24 {
    padding-right: 6rem;
  }

  .xl\:pr-28 {
    padding-right: 7rem;
  }

  .xl\:pr-4 {
    padding-right: 1rem;
  }

  .xl\:pr-56 {
    padding-right: 14rem;
  }

  .xl\:pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:pt-12 {
    padding-top: 3rem;
  }

  .xl\:pt-14 {
    padding-top: 3.5rem;
  }

  .xl\:pt-16 {
    padding-top: 4rem;
  }

  .xl\:pt-2 {
    padding-top: .5rem;
  }

  .xl\:pt-20 {
    padding-top: 5rem;
  }

  .xl\:pt-28 {
    padding-top: 7rem;
  }

  .xl\:pt-3 {
    padding-top: .75rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:pt-4 {
    padding-top: 1rem;
  }

  .xl\:pt-40 {
    padding-top: 10rem;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:pt-8 {
    padding-top: 2rem;
  }

  .xl\:pt-80 {
    padding-top: 20rem;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-start {
    text-align: start;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
  }

  .xl\:text-3\.5xl {
    font-size: 2rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
  }

  .xl\:text-4\.2xl {
    font-size: 2.6rem;
  }

  .xl\:text-4\.5xl {
    font-size: 2.875rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
  }

  .xl\:text-5\.2xl {
    font-size: 3.25rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
  }

  .xl\:text-\[13px\] {
    font-size: 13px;
  }

  .xl\:text-\[32px\] {
    font-size: 32px;
  }

  .xl\:text-\[56px\] {
    font-size: 56px;
  }

  .xl\:text-\[6rem\] {
    font-size: 6rem;
  }

  .xl\:text-base {
    font-size: 1rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
  }

  .xl\:text-tiny {
    font-size: .875rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
  }

  .xl\:leading-10 {
    line-height: 2.5rem;
  }

  .xl\:leading-11 {
    line-height: 3.5rem;
  }

  .xl\:leading-12 {
    line-height: 4rem;
  }

  .xl\:leading-13 {
    line-height: 3rem;
  }

  .xl\:leading-14 {
    line-height: 4.5rem;
  }

  .xl\:leading-6 {
    line-height: 1.5rem;
  }

  .xl\:leading-7 {
    line-height: 1.75rem;
  }

  .xl\:leading-8 {
    line-height: 2rem;
  }

  .xl\:text-\[\#C99FFF\] {
    --tw-text-opacity: 1;
    color: rgb(201 159 255 / var(--tw-text-opacity));
  }

  .xl\:text-\[\#DBB9FD\] {
    --tw-text-opacity: 1;
    color: rgb(219 185 253 / var(--tw-text-opacity));
  }

  .xl\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .xl\:text-violet-700 {
    --tw-text-opacity: 1;
    color: rgb(109 40 217 / var(--tw-text-opacity));
  }

  .xl\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .xl\:underline {
    text-decoration-line: underline;
  }

  .xl\:underline-offset-4 {
    text-underline-offset: 4px;
  }

  .xl\:invert-0 {
    --tw-invert: invert(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .group:hover .xl\:group-hover\:block {
    display: block;
  }

  .group:hover .xl\:group-hover\:hidden {
    display: none;
  }
}

@media (width >= 1536px) {
  .\32 xl\:visible {
    visibility: visible;
  }

  .\32 xl\:absolute {
    position: absolute;
  }

  .\32 xl\:-top-10 {
    top: -2.5rem;
  }

  .\32 xl\:order-first {
    order: -9999;
  }

  .\32 xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .\32 xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .\32 xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .\32 xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .\32 xl\:float-right {
    float: right;
  }

  .\32 xl\:float-left {
    float: left;
  }

  .\32 xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .\32 xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .\32 xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .\32 xl\:mb-0 {
    margin-bottom: 0;
  }

  .\32 xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .\32 xl\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .\32 xl\:mb-20 {
    margin-bottom: 5rem;
  }

  .\32 xl\:mb-24 {
    margin-bottom: 6rem;
  }

  .\32 xl\:mb-28 {
    margin-bottom: 7rem;
  }

  .\32 xl\:mb-32 {
    margin-bottom: 8rem;
  }

  .\32 xl\:mb-36 {
    margin-bottom: 9rem;
  }

  .\32 xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .\32 xl\:mb-52 {
    margin-bottom: 13rem;
  }

  .\32 xl\:mb-\[25rem\] {
    margin-bottom: 25rem;
  }

  .\32 xl\:ml-auto {
    margin-left: auto;
  }

  .\32 xl\:mt-0 {
    margin-top: 0;
  }

  .\32 xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:flex {
    display: flex;
  }

  .\32 xl\:inline-flex {
    display: inline-flex;
  }

  .\32 xl\:grid {
    display: grid;
  }

  .\32 xl\:hidden {
    display: none;
  }

  .\32 xl\:h-20 {
    height: 5rem;
  }

  .\32 xl\:h-24 {
    height: 6rem;
  }

  .\32 xl\:h-32 {
    height: 8rem;
  }

  .\32 xl\:h-52 {
    height: 13rem;
  }

  .\32 xl\:h-80, .\32 xl\:h-\[20rem\] {
    height: 20rem;
  }

  .\32 xl\:h-\[32rem\] {
    height: 32rem;
  }

  .\32 xl\:h-\[35rem\] {
    height: 35rem;
  }

  .\32 xl\:h-\[38rem\] {
    height: 38rem;
  }

  .\32 xl\:h-\[530px\] {
    height: 530px;
  }

  .\32 xl\:h-\[600px\] {
    height: 600px;
  }

  .\32 xl\:h-\[900px\] {
    height: 900px;
  }

  .\32 xl\:h-\[auto\] {
    height: auto;
  }

  .\32 xl\:h-full {
    height: 100%;
  }

  .\32 xl\:h-screen {
    height: 100vh;
  }

  .\32 xl\:w-1\/2 {
    width: 50%;
  }

  .\32 xl\:w-10\/12 {
    width: 83.3333%;
  }

  .\32 xl\:w-11\/12 {
    width: 91.6667%;
  }

  .\32 xl\:w-16 {
    width: 4rem;
  }

  .\32 xl\:w-2\/3 {
    width: 66.6667%;
  }

  .\32 xl\:w-2\/4 {
    width: 50%;
  }

  .\32 xl\:w-2\/5 {
    width: 40%;
  }

  .\32 xl\:w-20 {
    width: 5rem;
  }

  .\32 xl\:w-3\/4 {
    width: 75%;
  }

  .\32 xl\:w-3\/5 {
    width: 60%;
  }

  .\32 xl\:w-32 {
    width: 8rem;
  }

  .\32 xl\:w-4\/5 {
    width: 80%;
  }

  .\32 xl\:w-4\/6 {
    width: 66.6667%;
  }

  .\32 xl\:w-40 {
    width: 10rem;
  }

  .\32 xl\:w-44 {
    width: 11rem;
  }

  .\32 xl\:w-7\/12 {
    width: 58.3333%;
  }

  .\32 xl\:w-8\/12 {
    width: 66.6667%;
  }

  .\32 xl\:w-83 {
    width: 83%;
  }

  .\32 xl\:w-85 {
    width: 85%;
  }

  .\32 xl\:w-auto {
    width: auto;
  }

  .\32 xl\:w-full {
    width: 100%;
  }

  .\32 xl\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\32 xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .\32 xl\:flex-row {
    flex-direction: row;
  }

  .\32 xl\:flex-col {
    flex-direction: column;
  }

  .\32 xl\:items-start {
    align-items: flex-start;
  }

  .\32 xl\:justify-start {
    justify-content: flex-start;
  }

  .\32 xl\:justify-end {
    justify-content: flex-end;
  }

  .\32 xl\:justify-center {
    justify-content: center;
  }

  .\32 xl\:justify-between {
    justify-content: space-between;
  }

  .\32 xl\:justify-around {
    justify-content: space-around;
  }

  .\32 xl\:gap-14 {
    gap: 3.5rem;
  }

  .\32 xl\:gap-20 {
    gap: 5rem;
  }

  .\32 xl\:gap-28 {
    gap: 7rem;
  }

  .\32 xl\:gap-4 {
    gap: 1rem;
  }

  .\32 xl\:gap-6 {
    gap: 1.5rem;
  }

  .\32 xl\:gap-8 {
    gap: 2rem;
  }

  .\32 xl\:gap-y-20 {
    row-gap: 5rem;
  }

  .\32 xl\:border-b-2 {
    border-bottom-width: 2px;
  }

  .\32 xl\:border-r-0 {
    border-right-width: 0;
  }

  .\32 xl\:border-r-2 {
    border-right-width: 2px;
  }

  .\32 xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .\32 xl\:bg-talent-quest-hero-background {
    background-image: url("talent-quest-hero-bg.4c890917.svg");
  }

  .\32 xl\:bg-contain {
    background-size: contain;
  }

  .\32 xl\:bg-cover {
    background-size: cover;
  }

  .\32 xl\:bg-center {
    background-position: center;
  }

  .\32 xl\:bg-left {
    background-position: 0;
  }

  .\32 xl\:bg-right {
    background-position: 100%;
  }

  .\32 xl\:p-0 {
    padding: 0;
  }

  .\32 xl\:p-12 {
    padding: 3rem;
  }

  .\32 xl\:p-16 {
    padding: 4rem;
  }

  .\32 xl\:p-20 {
    padding: 5rem;
  }

  .\32 xl\:p-3 {
    padding: .75rem;
  }

  .\32 xl\:p-5 {
    padding: 1.25rem;
  }

  .\32 xl\:p-6 {
    padding: 1.5rem;
  }

  .\32 xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .\32 xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .\32 xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .\32 xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .\32 xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .\32 xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .\32 xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .\32 xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .\32 xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .\32 xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .\32 xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .\32 xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .\32 xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .\32 xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .\32 xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .\32 xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .\32 xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .\32 xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .\32 xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .\32 xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .\32 xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .\32 xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .\32 xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .\32 xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .\32 xl\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .\32 xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .\32 xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .\32 xl\:pb-\[28rem\] {
    padding-bottom: 28rem;
  }

  .\32 xl\:pl-20 {
    padding-left: 5rem;
  }

  .\32 xl\:pl-24 {
    padding-left: 6rem;
  }

  .\32 xl\:pl-28 {
    padding-left: 7rem;
  }

  .\32 xl\:pl-40 {
    padding-left: 10rem;
  }

  .\32 xl\:pr-0 {
    padding-right: 0;
  }

  .\32 xl\:pr-16 {
    padding-right: 4rem;
  }

  .\32 xl\:pr-20 {
    padding-right: 5rem;
  }

  .\32 xl\:pr-24 {
    padding-right: 6rem;
  }

  .\32 xl\:pt-0 {
    padding-top: 0;
  }

  .\32 xl\:pt-14 {
    padding-top: 3.5rem;
  }

  .\32 xl\:pt-20 {
    padding-top: 5rem;
  }

  .\32 xl\:pt-6 {
    padding-top: 1.5rem;
  }

  .\32 xl\:text-left {
    text-align: left;
  }

  .\32 xl\:text-center {
    text-align: center;
  }

  .\32 xl\:text-2\.5xl {
    font-size: 1.7rem;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
  }

  .\32 xl\:text-3\.5xl {
    font-size: 2rem;
  }

  .\32 xl\:text-3xl {
    font-size: 1.875rem;
  }

  .\32 xl\:text-4\.5xl {
    font-size: 2.875rem;
  }

  .\32 xl\:text-4xl {
    font-size: 2.25rem;
  }

  .\32 xl\:text-5\.2xl {
    font-size: 3.25rem;
  }

  .\32 xl\:text-5xl {
    font-size: 3rem;
  }

  .\32 xl\:text-\[6rem\] {
    font-size: 6rem;
  }

  .\32 xl\:text-base {
    font-size: 1rem;
  }

  .\32 xl\:text-lg {
    font-size: 1.125rem;
  }

  .\32 xl\:text-xl {
    font-size: 1.25rem;
  }

  .\32 xl\:leading-8 {
    line-height: 2rem;
  }

  .\32 xl\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .\32 xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 #0000000d;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}
/*# sourceMappingURL=about-us.b2c35c63.css.map */
